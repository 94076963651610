import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { DeviceService } from '@helpers/device.service';
import { SeoService } from '@shared/services/meta-tags.service';
import {
  Observable,
  Subject,
  Subscription,
  takeUntil
} from 'rxjs';
import { metaTags } from './meta';
import { SettingsService } from './settings.service';
import { AddressService } from '@shared/services';
import { environment } from '@environments/environment';
import { ProgressService } from '@app/progress/progress.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  #registerDevice$: Subscription;
  isMobile = false;
  preloaderPercent$: Observable<number>;
  private destroy$: Subject<void> = new Subject<void>();
  @HostListener('window:popstate', ['$event'])
  onPopState() {
    if (this._settingsService.modalRef) {
      this._settingsService.modalRef.close();
    }
  }

  constructor(
    private _deviceService: DeviceService,
    private _settingsService: SettingsService,
    private _metaService: Meta,
    private _seoService: SeoService,
    private _addressService: AddressService,
    private _progressService: ProgressService,
  ) {}

  ngOnInit() {
    this.preloaderPercent$ = this._progressService.percentCount$;
    this.#registerDevice$ = this._deviceService.getDeviceUUID().pipe(takeUntil(this.destroy$)).subscribe();

    this._settingsService
      .isMobile()
      .pipe(takeUntil(this.destroy$))
      .subscribe((isMobile) => {
        this.isMobile = isMobile;
      });

    this.setMetaTags();

    const country = {
      id: environment.appDefaultSettings.countries.kazakhstan.id,
      name: environment.appDefaultSettings.countries.kazakhstan.name[this._settingsService.getCurrentLanguageAsKey()],
      parentCodeCountry: environment.appDefaultSettings.countries.kazakhstan.code,
    };

    this._addressService.setCurrentCountry(country);
  }

  setMetaTags(): void {
    metaTags.tags.forEach((tag) => {
      this._metaService.updateTag({
        name: tag.name,
        content: tag.content,
      });
    });
    this._seoService.setTitle('Витрина Недвижимости – Ваш путеводитель в мире казахстанской недвижимости');
  }

  ngOnDestroy() {
    if (this.#registerDevice$?.closed === false) {
      this.#registerDevice$.unsubscribe();
    }
    this.destroy$.next();
    this.destroy$.complete();
  }
}
